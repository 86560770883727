import { useEffect, useState } from "react";
import { fetchData, putFormData } from "../../helpers/endpoints";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./style.scss";
import { Layout } from "../../components/layout";
import Questionnaire from "./components/Questionnaire";
import Documents from "./components/Documents";
import PatientList from "./components/PatientList";
import { useAssets, useMobile } from "../../hooks";
import { CustomButton } from "../../components/CustomButton";
import { useParams } from "react-router-dom";
import Appointments from "./components/Appointment";
import { FileUploader } from "react-drag-drop-files";
import Swal from "sweetalert2";
import Modal from "react-modal";
import imageCompression from "browser-image-compression";
import Services from "./components/Services";
import { useNavigate, useLocation } from "react-router-dom";
import MyModal from "./components/modal/Mymodal";

const Dashboard = () => {
  const customStyles = {
    overlay: {
      backgroundColor: "rgba(24, 50, 78, 0.9)",
      zIndex: "11",
    },
  };
  const navigator = useNavigate();
  const pathname = useLocation().pathname;
  const { patientId, defultTab, serviceId } = useParams();
  const [email, setEmail] = useState("");
  const [email2, setEmail2] = useState("");
  const [name, setName] = useState("");
  const [user, setuser] = useState("");
  const [mrn, setMrn] = useState("");
  const [img, setImg] = useState("");
  const [photo, setPhoto] = useState("");
  const { getFile } = useAssets();
  const isMobile = useMobile();
  const [tabIndex, setTabIndex] = useState(
    pathname == "/dashboard/0"
      ? 0
      : defultTab
      ? defultTab == "form"
        ? 0
        : defultTab == "document"
        ? 1
        : defultTab == "service" || defultTab == "purchase"
        ? 3
        : defultTab == "appointment"
        ? 2
        : 0
      : 0
  );
  const [show, setShow] = useState(false);
  const [hasPatient, setHasPatient] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const uploadImage = async () => {
    setIsLoading(true);
    if (img.size) {
      if (img.size > 5 * 1024 * 1024) {
        Swal.fire({
          title: "",
          text: "Please upload a correct file.",
          icon: "error",
          confirmButtonText: "Ok",
        });
      } else {
        const options = {
          maxSizeMB: 1,
        };
        const compressedFile = await imageCompression(img, options);
        let formData = new FormData();
        formData.append("file", compressedFile);
        var res = await putFormData(
          "patient/" + patientId + "/photo/upload",
          formData
        );
        if (res.status === 200) {
          setIsOpen(false);
          fetchUser();
        } else {
          Swal.fire({
            title: "",
            text: res,
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      }
    } else {
      Swal.fire({
        title: "",
        text: "Please upload a correct file.",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
    setIsLoading(false);
  };

  async function fetchUser() {
    if (patientId == 0) {
      setHasPatient(false);
      setPhoto("");
      setEmail("");
      setName(" ");
      var res = await fetchData("user");
      if (res.status === 200) {
        setuser(res.data.data);
      }
    } else {
      setHasPatient(true);
      var res = await fetchData("patient/" + patientId);
      setEmail(
        res.data.data.relationship === "me" ||
          res.data.data.relationship === "ME"
          ? ""
          : res.data.data.relationship
      );
      var res2 = await fetchData("user/config");

      setName(res.data.data.firstName + " " + res.data.data.lastName);
      setMrn(res.data.data.clinicalCode);
      setPhoto(
        res.data.data.photo
          ? res2.data.data.storage.bucket.url + "/" + res.data.data.photo
          : null
      );
      var res2 = await fetchData("user");

      if (res2.status === 200) {
        setuser(res2.data.data);
        setEmail2(res2.data.data.email);
      }
    }
  }

  useEffect(() => {
    if (pathname == "/dashboard/0") {
      setTabIndex(0);
    }
    if (defultTab == "service") {
      setTabIndex(3);
    }
    fetchUser();
  }, [patientId]);
  return (
    <Layout>
      <div
        // style={patientId == 0 && isMobile ? { paddingTop: "5vh" } : {}}
        className="dashboard"
      >
        <div className="dashboard-profile d-flex flex-row gap-3" dir="ltr">
          {patientId != 0 && (
            <div
              onClick={() => {
                setIsOpen(true);
              }}
              className="d-flex flex-column "
            >
              <img
                onClick={() => {
                  setIsOpen(true);
                }}
                style={
                  photo
                    ? isMobile
                      ? {
                          borderRadius: "50%",
                          marginBottom: "-24px",
                          width: "50px",
                          height: "50px",
                        }
                      : {
                          borderRadius: "50%",
                          marginBottom: "-24px",
                          width: "100px",
                          height: "100px",
                        }
                    : { marginBottom: "-24px" }
                }
                src={photo ? photo : getFile("LightProfile")}
                alt="profile"
              />
              <svg
                onClick={() => {
                  setIsOpen(true);
                }}
                style={
                  isMobile
                    ? {
                        marginLeft: "17px",
                        width: "18",
                        height: "18",
                        marginTop: "5px",
                      }
                    : {
                        marginTop: "-25px",
                        marginLeft: "80px",
                      }
                }
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_b_2932_105653)">
                  <circle
                    cx="12"
                    cy="12.9979"
                    r="12"
                    fill="rgba(255, 255, 255, 1)"
                  />
                  <circle
                    cx="12"
                    cy="12.9979"
                    r="11"
                    stroke="#212529"
                    stroke-opacity="1"
                  />
                </g>
                <path
                  d="M15.4677 12.0617L15.6517 12.2455L8.89883 18.9984H6V16.0997L12.7528 9.3465L15.2839 11.8776L15.4677 12.0617Z"
                  fill="#18324E"
                />
                <path
                  d="M17.7497 10.1473L16.2641 11.6329L13.3652 8.73409L14.8509 7.24846C15.185 6.91441 15.7288 6.91441 16.0629 7.24846L17.7498 8.93542C18.0837 9.26934 18.0837 9.81302 17.7497 10.1473H17.7497Z"
                  fill="#18324E"
                />
                <defs>
                  <filter
                    id="filter0_b_2932_105653"
                    x="-25"
                    y="-24.0021"
                    width="74"
                    height="74"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur
                      in="BackgroundImageFix"
                      stdDeviation="12.5"
                    />
                    <feComposite
                      in2="SourceAlpha"
                      operator="in"
                      result="effect1_backgroundBlur_2932_105653"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_backgroundBlur_2932_105653"
                      result="shape"
                    />
                  </filter>
                  <linearGradient
                    id="paint0_linear_2932_105653"
                    x1="4.79677e-07"
                    y1="24.1531"
                    x2="11.6348"
                    y2="6.92536"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="white" stop-opacity="0.4" />
                    <stop offset="1" stop-color="white" stop-opacity="0.1" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          )}
          <div className="d-flex flex-column pb-3">
            <span className="emailSpan">{name}</span>
            {email && <span className="nameSpan">{email}</span>}
          </div>

          {isMobile && (
            <img
              src={!show ? getFile("menue") : getFile("DarkClose")}
              style={
                isMobile
                  ? {
                      width: "24px",
                      position: "absolute",
                      right: "12%",
                      // marginTop: "8%",
                      zIndex: 1,
                    }
                  : {
                      width: "24px",
                      position: "absolute",
                      right: "12%",
                      marginTop: "8%",
                      zIndex: 1,
                    }
              }
              className="menuIcon"
              onClick={() => {
                setShow(!show);
              }}
            />
          )}
          {show && (
            <div className="mobile-tab d-flex flex-column">
              {patientId == 0 && (
                <CustomButton
                  title={"Patient’s info"}
                  onClick={() => {
                    setTabIndex(0);
                  }}
                  uiType={tabIndex != 0 ? "outlined2" : "primary"}
                  fullWidth
                />
              )}

              {hasPatient && (
                <>
                  <CustomButton
                    title={"Questionnaire"}
                    onClick={() => {
                      setTabIndex(0);

                      navigator("/dashboard/" + patientId + "/form");
                      setShow(false);
                    }}
                    uiType={tabIndex != 0 ? "outlined2" : "primary"}
                    fullWidth
                  />

                  <CustomButton
                    title={"Documentation"}
                    onClick={() => {
                      setTabIndex(1);

                      navigator("/dashboard/" + patientId + "/document");
                      setShow(false);
                    }}
                    uiType={tabIndex != 1 ? "outlined2" : "primary"}
                    fullWidth
                  />
                  <CustomButton
                    title={"Appointments"}
                    onClick={() => {
                      setTabIndex(2);

                      navigator("/dashboard/" + patientId + "/appointment");
                      setShow(false);
                    }}
                    uiType={tabIndex != 2 ? "outlined2" : "primary"}
                    fullWidth
                  />
                  <CustomButton
                    title={"Services"}
                    onClick={() => {
                      setTabIndex(3);

                      navigator("/dashboard/" + patientId + "/service");
                      setShow(false);
                    }}
                    uiType={tabIndex != 3 ? "outlined2" : "primary"}
                    fullWidth
                  />
                </>
              )}
            </div>
          )}
        </div>
        {isMobile && <div className="divider" />}
        <Tabs
          direction={"rtl"}
          selectedIndex={tabIndex}
          onSelect={(index) => {
            setTabIndex(index);
            var temp =
              index == 3
                ? "service" || "purchase"
                : index == 0
                ? "form"
                : index == 1
                ? "document"
                : "appointment";

            navigator("/dashboard/" + patientId + "/" + temp);
          }}
        >
          {!isMobile && (
            <TabList>
              {patientId == 0 && <Tab>Patients info</Tab>}
              {hasPatient && (
                <>
                  <Tab>Questionnaire</Tab>
                  <Tab>Documentation</Tab>
                  <Tab>Appointments</Tab>
                  <Tab>Services</Tab>
                </>
              )}
            </TabList>
          )}

          {patientId == 0 && (
            <TabPanel>
              <PatientList user={user} />
            </TabPanel>
          )}

          <TabPanel>
            <Questionnaire email={email} patientId={patientId} />
          </TabPanel>
          <TabPanel>
            <Documents patientId={patientId} />
          </TabPanel>

          <TabPanel>
            <Appointments
              patientId={patientId}
              name={name}
              mrn={mrn}
              email={email2}
            />
          </TabPanel>
          <TabPanel>
            <Services
              patientId={patientId}
              name={name}
              mrn={mrn}
              email={email2}
              serviceId={serviceId}
              userId={user.id}
            />
          </TabPanel>
        </Tabs>
      </div>
      {/* <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
        className="myModal"
      ></Modal> */}
      <MyModal
        isOpen={isOpen}
        setisopen={setIsOpen}
        isLoading={isLoading}
        // Title="You can upload up to 5 MB."
      >
        <div style={{ paddingTop: "20px" }}>
          <p
            style={{
              display: "block",
              textAlign: "center",
              paddingTop: "10px",
              paddingBottom: "10px",
            }}
          >
            You can upload up to 5 MB.
          </p>
          <p>
            <FileUploader
              required
              handleChange={(file) => setImg(file)}
              name="file"
              types={["JPG", "PNG"]}
              maxSize={5}
            />
            <div
              style={{
                display: "block",
                textAlign: "center",
                paddingTop: "10px",
              }}
            >
              <CustomButton
                uiType="primary2"
                title={"Done"}
                additionalClassNames="col-4"
                onClick={uploadImage}
                loading={isLoading}
              />
            </div>
          </p>
        </div>
      </MyModal>
    </Layout>
  );
};

export default Dashboard;
