import React, { useState } from "react";
import { CustomInput } from "../../components/CustomInput";
import { CustomButton } from "../../components/CustomButton";
import { useAssets, useMobile } from "../../hooks";
import { EmailValidate } from "../../helpers/validate";
import { postWithoutToket } from "../../helpers/endpoints";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import "./style.scss";
import { useLocation } from "react-router-dom";

const SignIn = () => {
  const { getFile } = useAssets();
  const isMobile = useMobile();
  const [password, setPassword] = useState("");
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [remember, setRemmember] = useState(true);
  const navigator = useNavigate();
  const location = useLocation();

  const handleSubmit = async () => {
    setIsLoading(true);
    if (!password) {
      Swal.fire({
        title: "Please fill the required fields",
        text: "Password cannot be empty",
        icon: "error",
        confirmButtonText: "Ok",
      });
      setIsLoading(false);
      return;
    }
    if (EmailValidate(input)) {
      const body = { email: input, password: password };
      var res = await postWithoutToket("auth/login", JSON.stringify(body));
      if (res.status !== 201) {
        Swal.fire({
          title: "",
          text: res,
          icon: "error",
          confirmButtonText: "Ok",
        });
      } else {
        Cookies.set("access_token", res.data.data.token);
        if (res.data.data.profile) {
          if (remember) {
            Cookies.set("isLogin", true, { expires: 365 });
          }
          Cookies.set("email", input);
          var is_default = JSON.parse(localStorage.getItem("defaultPatient"));
          var default_date = new Date(
            JSON.parse(localStorage.getItem("date_default_patient"))
          );
          var date = new Date();
          const difference = date.getTime() - default_date.getTime();
          // const { from } = location ? location.state : {};
          // if (from) {
          //   navigator(from);
          // } else {
          if (is_default && default_date && difference < 2592000000) {
            navigator("/dashboard/" + is_default + "/service");
          } else {
            navigator("/dashboard/0", { state: { from: "/sign-in" } });
          }
          // }
        } else {
          if (remember) {
            Cookies.set("isLogin", true, { expires: 365 });
          }
          Cookies.set("email", input);
          navigator("/profile", { state: { email: input } });
        }
      }
    } else {
      Swal.fire({
        title: "Invalid Email",
        text: "Please enter a valid email address",
        icon: "error",
        confirmButtonText: "Ok",
      });
    }
    setIsLoading(false);
  };

  return (
    <div className="sign-in d-flex flex-row">
      {!isMobile && (
        <div className="sign-in-img col-6">
          <div className=" d-flex flex-column justify-content-center align-items-center gap-3">
            <h3>Welcome to</h3>
            <h2>All Brains Clinic</h2>
          </div>
        </div>
      )}
      <div className=" col-lg-6 col-12">
        <div className="sign-in-box-header d-flex flex-row align-items-center pt-4 px-4 ">
          <img src={getFile("BackIcon")} alt="back" />
          <a href="https://allbrainsclinic.com/" target={"_self"}>
            {" "}
            <img src={getFile("Logo")} alt="logo" />{" "}
          </a>
        </div>

        <div className="sign-in-box d-flex flex-column justify-content-center align-items-center gap-4 pt-4">
          <h2>Sign In</h2>

          <CustomInput
            fullWidth
            label={"Email"}
            placeholder="Enter your Email"
            required
            value={input}
            onChange={(e) => {
              setInput(e.toLowerCase().trim());
            }}
          />
          <CustomInput
            fullWidth
            type="password"
            label={"Password"}
            placeholder="Enter your Password"
            required
            value={password}
            onChange={(e) => {
              setPassword(e);
            }}
          />
          <div className="d-flex flex-row pb-3  w-100">
            <div
              style={
                isMobile ? { marginLeft: "15px" } : { paddingLeft: "10px" }
              }
            >
              <input
                type={"checkbox"}
                value="Remember me"
                onChange={(e) => {
                  setRemmember(e);
                }}
                checked={remember}
              />
              <label className="lable px-1"> Remember me</label>
            </div>
            <a
              style={
                isMobile
                  ? { position: "absolute", right: "1.5rem" }
                  : { position: "absolute", right: "4rem" }
              }
              href="/forget-password"
            >
              Forgot Password
            </a>
          </div>
          <CustomButton
            // fullWidth
            uiType="primary2"
            title={"Sign In"}
            additionalClassNames={isMobile ? "py-3 mx-5 col-11" : "py-3 col-12"}
            onClick={handleSubmit}
            loading={isLoading}
          />
          <h3>
            Don’t have an account?{"  "}
            <a style={{ paddingLeft: "5px" }} href="/sign-up">
              {" "}
              {"  "}Sign Up
            </a>
          </h3>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
