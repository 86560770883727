import React, { useState } from "react";
import { CustomInput } from "../../components/CustomInput";
import { CustomButton } from "../../components/CustomButton";
import { useAssets, useMobile } from "../../hooks";
import { putData } from "../../helpers/endpoints";
import Swal from "sweetalert2";
import "./style.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { PhoneValidate } from "../../helpers/validate";

import Cookies from "js-cookie";

const Profile = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isValid, setIsValid] = useState(true);
  const { getFile } = useAssets();
  const isMobile = useMobile();
  const [password, setPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [confPassword, setConfPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { state } = useLocation();
  const { email } = state;
  const navigator = useNavigate();
  const handleSubmit = async () => {
    let regex = /.*\d.*/;
    if (regex.test(firstName) || regex.test(lastName)) {
      // Modal.error({ title: "firstname & lastname can't includes number" });

      Swal.fire({
        title: "firstname & lastname can't includes number",
        // text: errorMessage,
        icon: "error",
        confirmButtonText: "Ok",
      });
      return;
    }
    setIsLoading(true);
    let errorTitle = "Please fill in the required fields.";
    let errorMessage = "";

    if (confPassword !== password) {
      errorMessage = "The password and confirmation password do not match.";
    } else if (password.length < 8) {
      errorMessage = "Password must be at least 8 characters long.";
    } else if (!firstName) {
      errorMessage = "First Name cannot be empty.";
    } else if (!lastName) {
      errorMessage = "Last Name cannot be empty.";
    } else if (!phone) {
      errorMessage = "Phone Number cannot be empty.";
    } else if (!PhoneValidate(phone) || !isValid) {
      errorMessage = "Please enter a valid phone number.";
    }

    if (errorMessage) {
      Swal.fire({
        title: errorTitle,
        text: errorMessage,
        icon: "error",
        confirmButtonText: "Ok",
      });
      setIsLoading(false);
      return;
    }

    const data = {
      firstName,
      lastName,
      phoneNumberCountryCode: "+64",
      phoneNumber: phone,
      password,
      password_confirmation: confPassword,
    };

    try {
      const res = await putData("user", JSON.stringify(data));
      if (res.status !== 200) {
        Swal.fire({
          title: "",
          text: res,
          icon: "error",
          confirmButtonText: "Ok",
        });
      } else {
        Cookies.set("email", email);
        navigator("/dashboard/0");
      }
    } catch (error) {
      console.log("Error:", error);
    }

    setIsLoading(false);
  };

  return (
    <div className="profile row">
      <div className=" col-lg-6 col-12">
        <div className="profile-box-header d-flex flex-row align-items-center pt-2 px-4 ">
          <img
            src={getFile("BackIcon")}
            alt="back"
            onClick={() => navigator("/sign-up")}
          />
          <a href="https://allbrainsclinic.com/" target={"_self"}>
            {" "}
            <img src={getFile("Logo")} alt="logo" />{" "}
          </a>
        </div>

        <div
          style={
            isMobile
              ? { paddingLeft: "2rem", paddingRight: "1rem" }
              : { paddingLeft: "3rem" }
          }
          className="profile-box d-flex flex-column justify-content-center align-items-center gap-3 pt-5"
        >
          <h2>Sign Up</h2>

          <CustomInput
            fullWidth
            label={"Email"}
            placeholder="Enter your Email"
            required
            value={email}
            readOnly
          />
          <CustomInput
            fullWidth
            label={"Phone Number"}
            placeholder="Enter your Phone Number"
            required
            value={phone}
            onChange={(e) => {
              const input = e;
              const phoneRegex = /^\+?[1-9]\d{1,14}$/;
              setPhoneNumber(input);
              setIsValid(phoneRegex.test(input));
              setPhone(e.trim());
            }}
          />
          <CustomInput
            fullWidth
            label={"First Name"}
            placeholder="Enter your First Name"
            required
            value={firstName}
            onChange={(e) => {
              setFirstName(e.trim());
            }}
            pattern="[a-zA-Z]*"
          />
          <CustomInput
            fullWidth
            label={"Last Name"}
            autoComplete="off"
            placeholder="Enter your Last Name"
            required
            value={lastName}
            onChange={(e) => {
              setLastName(e.trim());
            }}
            pattern="[a-zA-Z]*"
          />
          <CustomInput
            type="password"
            fullWidth
            autoComplete="off"
            label={"Password"}
            placeholder="Enter your Password"
            required
            value={password}
            onChange={(e) => {
              setPassword(e);
            }}
          />
          <h3
            style={
              password && password.length < 8
                ? {
                    color: "red",
                    textAlign: "left",
                    alignSelf: "baseline",
                    marginTop: "-5px",
                  }
                : {
                    color: "gray",
                    textAlign: "left",
                    alignSelf: "baseline",
                    marginTop: "-5px",
                  }
            }
          >
            Password must be at least 8 characters long..
          </h3>
          <CustomInput
            type="password"
            fullWidth
            label={" Confirm Password"}
            placeholder="Enter your Password"
            required
            value={confPassword}
            onChange={(e) => {
              setConfPassword(e);
            }}
          />

          <CustomButton
            fullWidth
            uiType="primary2"
            title={"Sign Up"}
            additionalClassNames="py-3"
            onClick={handleSubmit}
            loading={isLoading}
          />
        </div>
      </div>

      {!isMobile ? (
        <div className="profile-img col-6">
          <div className=" d-flex flex-column justify-content-center align-items-center gap-3">
            <h3>Welcome to</h3>
            <h2>All Brains Care</h2>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* {complate && (
        <div className=" col-12">
          <div className="sign-up-header d-flex flex-row align-items-center pt-2 px-4 ">
            <img src={getFile("BackIcon")} alt="back" />
            <a href="https://allbrainsclinic.com/" target={"_self"}>
              {" "}
              <img src={getFile("Logo")} alt="logo" />{" "}
            </a>
          </div>

          <div className="sign-up-box d-flex flex-column justify-content-center align-items-center gap-3 ">
            <h2>Sign Up</h2>
            <h3>
              <br />
              Thank you for choosing All Brains!
              <br /> Our team will contact you soon to confirm your Sign Up
              request.
              <br />
              <br />
            </h3>

            <CustomButton
              title={"Done"}
              additionalClassNames="px-5"
              href={"/sign-up"}
            />
          </div>
        </div>
      )} */}
    </div>
  );
};

export default Profile;
